<template>
	<article class="d-institution-page-custom">
		<LimboSearch
			v-slot="{ data, state }"
			:search-filters="filters"
			:config="{
				allowSearchFiltersMutation: true,
				enableLiveSearch: true,
				searchResponseTransformerMethod,
			}"
			class="relative"
		>
			<InstitutionPageHero
				:title="data ? data.title : title"
				:description="data ? data.headerDescription : headerDescription"
				:image="data ? data.headerImage : headerImage"
				v-bind="{ breadcrumb }"
			>
				<template #before>
					<div class="grid px-layout-margin">
						<div
							:class="[
								'flex flex-col w-full gap-layout-gutter bg-primary  >=1024:flex-row',
								{
									'mb-sm': !overviewUrl,
								},
							]"
						>
							<BaseInputDropdown
								class="w-full"
								:value="computedFilterValues.location"
								:config="computedFilters.location"
								:aria-label="
									computedFilters.location.placeholder
								"
								@input="
									(event) => (
										updateValue('location', event),
										updateInstitutions(event)
									)
								"
							/>
							<BaseInputDropdown
								class="w-full"
								:value="computedFilterValues.institution"
								:config="computedFilters.institution"
								:aria-label="
									computedFilters.institution.placeholder
								"
								@input="
									(event) => updateValue('institution', event)
								"
							/>
						</div>
						<NuxtLinkExt
							v-if="overviewUrl && overviewUrlTitle"
							:to="overviewUrl"
							:target="overviewUrl"
							class="inline-block my-sm w-fit justify-self-end"
						>
							<div :class="'flex gap-xs items-center group'">
								<span
									class="
										text-primary-button
										font-semibold
										uppercase
									"
								>
									{{ overviewUrlTitle }}
								</span>
								<BaseButton
									tag="button"
									:aria-label="`Gå til ${
										overviewUrlTitle || overviewUrl
									}`"
								>
									<template #icon>
										<SvgCaret
											:class="[
												'duration-200 ease-smooth-out',
												'group-hover:translate-y-2',
											]"
										/>
									</template>
								</BaseButton>
							</div>
						</NuxtLinkExt>
					</div>
				</template>
			</InstitutionPageHero>

			<div>
				<div v-if="state.isLoading" key="loading">
					<SearchLoader class="w-md mx-auto my-layout-gutter" />
				</div>
				<div v-if="data && data.length > 0">
					<div v-for="item in data" :key="item.id">
						{{ item.location }}
					</div>
				</div>
			</div>
			<template v-if="data && data.contentElements && !state.isLoading">
				<div class="mx-layout-margin relative mb-lg">
					<PageAside
						v-if="data.contentElements && data.showToc"
						class="<1024:hidden"
						:show-longread="data.showToc"
					/>

					<BlockList :block-list="data.contentElements" />
				</div>
			</template>
		</LimboSearch>
	</article>
</template>

<script>
import BlockList from '~/components/main/BlockList';
import InstitutionPageHero from '~/components/main/InstitutionPageHero.vue';
import PageAside from '~/components/shared/PageAside';
import SearchLoader from '~/components/shared/SearchLoader';
import SvgCaret from '~/assets/svgs/arrow-icon.svg?inline';
import LimboSearch from '~/citi-baseline/components/LimboSearch';
export default {
	components: {
		/* 	BlockList, */
		InstitutionPageHero,
		BlockList,
		LimboSearch,
		SearchLoader,
		PageAside,
		SvgCaret,
	},
	inheritAttrs: false,
	props: {
		overviewUrl: String,
		overviewUrlTitle: String,
		title: String,

		institutionForm: {
			type: Object,
			required: false,
		},
		locationFilters: {
			type: Object,
			required: false,
		},
		breadcrumb: {
			type: Array,
			required: false,
		},
		headerImage: {
			type: Object,
			required: false,
		},
		headerDescription: {
			type: String,
			required: false,
		},
		showToc: {
			type: Boolean,
			default: false,
		},
		path: {
			type: Array,
			required: false,
		},
		searchFilters: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			filters: this.searchFilters,
		};
	},
	computed: {
		computedFilters() {
			const response = {};
			this.searchFilters?.fields?.forEach((element) => {
				response[element.name] = element;
			});

			return response;
		},
		computedFilterValues() {
			const response = {};
			this.searchFilters?.fields?.forEach((element) => {
				response[element.name] = Array.isArray(element.value)
					? element.value.find((e) => e.checked)?.value
					: element.value;
			});

			return response;
		},
	},
	mounted() {
		this.updateInstitutions();
	},
	methods: {
		updateInstitutions() {
			this.$axios
				.get(
					`${this.locationFilters.endpointUrl}?contextId=${
						this.locationFilters.fields?.find(
							(field) => field.name === 'contextId'
						)?.value
					}&location=${
						this.computedFilters.location?.value?.find?.(
							(item) => item.checked
						)?.value ?? ''
					}`
				)
				.then(({ data = [] }) => {
					this.$set(this.computedFilters.institution, 'value', data);
				})
				.catch(() => {});
		},
		updateValue(name, event) {
			const field = this.filters.fields.find((e) => e.name === name);

			if (typeof event === 'string' && Array.isArray(field.value)) {
				field.value.forEach((element) => {
					element.checked = element.value === event;
				});
			} else if (typeof event === 'string') {
				field.value = event;
			} else {
				field.value = event?.target?.value ?? '';
			}
		},
		searchResponseTransformerMethod(res) {
			const newRes = {
				data: res,
			};
			return newRes;
		},
	},
};
</script>

<style>
.d-institution-page-custom .c-block-list-news-list-custom__all-news {
	display: none;
}
</style>
