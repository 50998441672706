<template>
	<nav class="c-page-breadcrumb" aria-label="Brødkrumme">
		<template v-for="(item, index) in computedData">
			<template v-if="index !== computedData.length - 1">
				<NuxtLinkExt
					:key="`crumb-${item.id}`"
					:to="item.url"
					:target="item.target"
					class="
						whitespace-nowrap
						mr-12
						text-text
						no-underline
						hover:underline
					"
					v-text="item.name"
				/>
				<SvgCaret
					:key="`seperator-${item.id}`"
					class="flex-shrink-0 w-10 inline mr-12"
				/>
			</template>
			<span
				v-else
				:key="`crumb-${item.id}`"
				class="whitespace-nowrap mr-12 text-text font-semibold"
				v-text="item.name"
			></span>
		</template>
	</nav>
</template>

<script>
import SvgCaret from '~/assets/svgs/caret-icon.svg?inline';

export default {
	name: 'PageBreadcrumb',
	components: { SvgCaret },

	props: {
		data: {
			type: Array,
			required: true,
		},
	},

	computed: {
		computedData() {
			const data = this.data.concat([]);
			data[0].name = 'Forside';
			return data;
		},
	},
};
</script>
